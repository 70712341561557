import logo from "./icons/logo.svg";
import bell from "./icons/bell.svg";
import addIcon from "./icons/add.svg";
import close from "./icons/close.svg";
import searchIcon from "./icons/search.svg";
import profile from "./images/profile.png";
import back from "./icons/back.svg";

export const icons = {
  logo,
  bell,
  addIcon,
  close,
  searchIcon,
  back,
};

export const images = {
  profile,
};
