import { Button, CustomTable, InputField, InputSelect } from "../../components";
import styles from "./Home.module.scss";
// import "antd/dist/antd.css";
import { MainLayout } from "../../layouts";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import {
  CampaignContext,
  CampgaignContext,
} from "../../utils/contexts/CampaignContext";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import { icons } from "../../assets";
import { CurrentContext } from "../../utils/contexts/CurrentContext";
import {
  isValidInstagramUrl,
  isValidYoutubeUrl,
  KMBFormatter,
  showAlert,
} from "../../utils";
import { tableData } from "../../utils/constants";
import clsx from "clsx";
import { CreatableMultipleSelect } from "../../components/CreatableSelect/CreatableSelect";
import { Close, DeleteOutlined } from "@mui/icons-material";
import { Button as MButton } from "@mui/material";
import { API_ALL, API_ARTIST } from "../../utils/API";
import { AuthContext } from "../../utils/auth/AuthContext";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Popconfirm } from "antd";

const { addIcon } = icons;

const sectorOptions = [
  {
    name: "Vlog",
    value: "vlog",
  },
  {
    name: "Makeup",
    value: "makeup",
  },
  {
    name: "Genz",
    value: "genz",
  },
  {
    name: "Skincare",
    value: "skincare",
  },
  {
    name: "Fitness",
    value: "fitness",
  },
  {
    name: "Couple",
    value: "couple",
  },
  {
    name: "Dance",
    value: "dance",
  },
  {
    name: "Comedy",
    value: "comedy",
  },
  {
    name: "Music",
    value: "music",
  },
];

const genderOptions = [
  {
    name: "Male",
    value: "male",
  },
  {
    name: "Female",
    value: "female",
  },
  {
    name: "Other",
    value: "other",
  },
];

const typeOptions = [
  {
    name: "Macro",
    value: "macro",
  },
  {
    name: "Mini",
    value: "mini",
  },
  {
    name: "Mega",
    value: "mega",
  },
];

const languageOptions = [
  {
    name: "Hindi",
    value: "hindi",
  },
  {
    name: "English",
    value: "english",
  },
  {
    name: "Marathi",
    value: "marathi",
  },
];

const Home = () => {
  const [filters, setFilters] = useState({});
  const [currentArtists, setCurrentArtists] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const { artists } = useContext(CampaignContext);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    async function getUserDetails() {
      const res = await API_ALL().get("/user/single", {
        params: {
          id: currentUser.id,
        },
      });
      setUserDetails(res.data);
    }
    if (currentUser) {
      getUserDetails();
    }
  }, [currentUser]);

  function debounce(func, timeout) {
    let timer;
    return (...args) => {
      if (!timer) {
        func.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  }

  function handleAddRow() {
    setArtistModalOpen(true);
  }

  function onSearch(e) {
    const { value } = e.target;
    setCurrentArtists(
      artists?.filter((artist) =>
        artist.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  }

  function handleChange(e) {
    const { id, value, name } = e.target;

    if (id === "search") {
      debounce(onSearch, 500)(e);
    }

    setFilters((prev) => {
      return {
        ...prev,
        [name ? name : id]: value,
      };
    });
  }

  useEffect(() => {
    setCurrentArtists(artists);
  }, [artists]);

  const [artistModalOpen, setArtistModalOpen] = useState(false);
  function handleClosArtistModal() {
    setArtistModalOpen(false);
  }

  async function handleSaveArtist(values) {
    console.log({ values });
    try {
      if (!values.youtube?.link?.length && !values.instagram?.link?.length) {
        console.log("Hello");
        return showAlert("error", "Please enter atleast one link");
      }
      if (values.youtube?.link?.length) {
        if (!isValidYoutubeUrl(values.youtube.link)) {
          return showAlert("error", "Please enter a valid youtube link");
        }
      }
      if (values.instagram?.link?.length) {
        if (!isValidInstagramUrl(values.instagram.link)) {
          return showAlert("error", "Please enter a valid instagram link");
        }
      }
      const response = await API_ARTIST().post("/create", values);
      setCurrentArtists((prev) => [response.data.data, ...prev]);
      console.log({ response });
      showAlert("success", "Artist added successfully");
      setArtistModalOpen(false);
    } catch (error) {
      console.log(error);
      showAlert("error", "Error: " + error?.response?.data?.message);
    }
  }

  async function handleDeleteArtist(id) {
    console.log({ id });
    try {
      const res = await API_ARTIST().delete("/delete", {
        params: {
          id,
        },
      });
      console.log({ res });
      setCurrentArtists(currentArtists.filter((item) => item._id !== id));
      showAlert("success", "Deleted Artist Successfully");
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      console.log(error);
    }
  }

  return (
    <MainLayout
      classes={[styles.container]}
      navbarProps={{
        titleProps: {
          name: userDetails?.name || "Agency Name",
          disabled: true,
        },
      }}
    >
      {/* <Header
        filters={filters}
        handleChange={handleChange}
        navigate={navigate}
      /> */}
      <div className={styles.header}>
        <Button
          onClick={() => navigate("/upload-artists")}
          rightIcon={<UploadFileIcon />}
        >
          Bulk Upload
        </Button>
        <div>
          <IconButton onClick={handleAddRow}>
            <img src={addIcon} alt="Add Row" />
          </IconButton>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <CustomTable
          setData={setCurrentArtists}
          columns={[
            ...tableData.campaign.info.columns,
            {
              title: "Del Artist",
              dataIndex: "delArtist",
              key: "delArtist",
              fixed: "right",
              width: 100,
              render: (text, record) => (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => handleDeleteArtist(record._id)}
                >
                  <IconButton>
                    <DeleteOutlined htmlColor="pink" />
                  </IconButton>
                </Popconfirm>
              ),
            },
          ]}
          data={currentArtists}
        />
      </div>

      <AddNewArtist
        open={artistModalOpen}
        handleClose={handleClosArtistModal}
        handleSave={handleSaveArtist}
      />
    </MainLayout>
  );
};

export default Home;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewArtist = ({ open, handleClose, handleSave }) => {
  const [values, setValues] = useState({});
  const [instagram, setInstagram] = useState({});
  const [youtube, setYoutube] = useState({});
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);

  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    console.log({ userDetails });
    if (userDetails?._id) {
      setValues((prev) => ({
        ...prev,
        agencyName: userDetails?.agencyName,
        manager: userDetails?.name,
        contact: userDetails?.contact,
        email: userDetails?.email,
      }));
    }
  }, [userDetails]);

  function handleChange(e) {
    const { id, value, name } = e.target;
    setValues((prev) => {
      return {
        ...prev,
        [name ? name : id]: value,
      };
    });
  }

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      instagram,
      youtube,
      categories: categories?.map((cat) => cat.value),
      languages: languages?.map((lang) => lang.value),
    }));
  }, [instagram, youtube, categories, languages]);

  async function handleAddLanguage(valueToAdd) {
    console.log(valueToAdd);
  }

  async function handleAddCategories(valueToAdd) {
    console.log(valueToAdd);
  }

  function handleInstagram(param) {
    return (e) => {
      setInstagram((prev) => ({ ...prev, [param]: e.target.value }));
    };
  }

  function handleYoutube(param) {
    return (e) => {
      setYoutube((prev) => ({ ...prev, [param]: e.target.value }));
    };
  }

  useEffect(() => {
    async function getSubsribers() {
      const res = await API_ALL().get("/youtube/subscribers", {
        params: {
          youtubeURI: values?.youtube?.link,
        },
      });
      setYoutube((prev) => ({ ...prev, subscribers: res?.data?.subscribers }));
    }
    if (values?.youtube?.link) {
      getSubsribers();
    }
  }, [values?.youtube?.link]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={styles.dialog}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave(values);
        }}
      >
        <AppBar sx={{ position: "sticky", top: 0 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <MButton
              autoFocus
              color="inherit"
              type="submit"
              // onClick={() => {
              //   handleSave(values);
              //   // handleClose();
              // }}
            >
              save
            </MButton>
          </Toolbar>
        </AppBar>
        <div className={styles.addNewArtist}>
          <div>
            <section className={styles.inputs}>
              <FormSection sectionName={"Artist Information"} sectionNumber={1}>
                <InputField
                  required
                  onChange={handleChange}
                  id="name"
                  value={values?.name}
                  label={"Name"}
                />
                <CreatableMultipleSelect
                  value={categories}
                  width={"950px"}
                  setValue={setCategories}
                  label={"Categories"}
                  id="categories"
                  onAddModalSubmit={handleAddCategories}
                  options={sectorOptions}
                />
                <CreatableMultipleSelect
                  required
                  width={"900px"}
                  name="languages"
                  label={"Languages"}
                  id="languages"
                  value={languages}
                  setValue={setLanguages}
                  options={languageOptions}
                  onAddModalSubmit={handleAddLanguage}
                />
                <InputSelect
                  required
                  name="type"
                  label={"Type"}
                  value={values?.type}
                  onChange={handleChange}
                  options={typeOptions}
                />
                <InputSelect
                  required
                  name="gender"
                  label={"Gender"}
                  value={values?.gender}
                  onChange={handleChange}
                  options={genderOptions}
                />
                <InputField
                  required
                  onChange={handleChange}
                  id="location"
                  value={values?.location}
                  label={"Location"}
                />
              </FormSection>
              <FormSection sectionName={"Instagram"} sectionNumber={2}>
                <InputField
                  onChange={handleInstagram("link")}
                  value={instagram?.link}
                  label={"Link"}
                />
                <InputField
                  onChange={handleInstagram("followers")}
                  value={instagram?.followers}
                  label={"Followers"}
                />

                <InputField
                  onChange={handleInstagram("reelCommercial")}
                  value={instagram?.reelCommercial}
                  label={"Reel Commercial"}
                />
                <InputField
                  onChange={handleInstagram("storyCommercial")}
                  value={instagram?.storyCommercial}
                  label={"Story Commercial"}
                />
                <InputField
                  onChange={handleInstagram("averageViews")}
                  value={instagram?.averageViews}
                  label={"Average Views"}
                />
              </FormSection>
              <FormSection sectionName={"Youtube"} sectionNumber={3}>
                <InputField
                  onChange={handleYoutube("link")}
                  value={youtube?.link}
                  label={"Link"}
                />
                <InputField
                  onChange={handleYoutube("subscribers")}
                  value={youtube?.subscribers}
                  label={"Subscribers"}
                  disabled
                />

                <InputField
                  onChange={handleYoutube("commercial")}
                  value={youtube?.commercial}
                  label={"Commercial"}
                />
                <InputField
                  onChange={handleYoutube("averageViews")}
                  value={youtube?.averageViews}
                  label={"Average Views"}
                />
              </FormSection>
              <FormSection sectionName={"Other"} sectionNumber={4}>
                <InputField
                  required
                  onChange={handleChange}
                  id="agencyName"
                  value={values?.agencyName}
                  label={"Agency Name"}
                />
                <InputField
                  required
                  onChange={handleChange}
                  id="manager"
                  value={values?.manager}
                  type="tel"
                  label={"Manager"}
                />
                <InputField
                  required
                  onChange={handleChange}
                  id="contact"
                  value={values?.contact}
                  type="tel"
                  label={"Contact"}
                />
                <InputField
                  required
                  onChange={handleChange}
                  id="email"
                  value={values?.email}
                  type="email"
                  label={"Email"}
                />
              </FormSection>
            </section>
            {/* <Button style={{ margin: "1rem 0" }} type="submit">
          Submit
        </Button> */}
          </div>
        </div>
      </form>
    </Dialog>
  );
};

const FormSection = ({ sectionName, children, sectionNumber }) => {
  return (
    <div className={styles.formSection}>
      <div className={clsx(styles.fontG500, styles.header)}>
        <div className={styles.sectionNumber}>{sectionNumber}</div>
        <div className={styles.sectionName}>{sectionName}</div>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};
