import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styles from "./App.module.scss";
import { Home, Login, Register, UploadArtists, ResetPassword } from "./pages/";
// import SelectArtists from "./pages/SelectArtists/SelectArtists";
// import UploadArtists from "./pages/UploadArtists/UploadArtists";
import AuthContextProvider from "./utils/auth/AuthContext";
import PrivateRoute from "./utils/auth/PrivateRoute";
import CampaignContextProvider from "./utils/contexts/CampaignContext";
import CurrentContextProvider from "./utils/contexts/CurrentContext";
import "./App.css";

function App() {
  return (
    <Router basename="/">
      <AuthContextProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/register" element={<Register />} />
        </Routes>
        <CampaignContextProvider>
          <CurrentContextProvider>
            <Routes>
              <Route path="/" element={<PrivateRoute component={Home} />} />
              <Route
                path="/upload-artists"
                element={<PrivateRoute component={UploadArtists} />}
              />
            </Routes>
          </CurrentContextProvider>
        </CampaignContextProvider>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
