import { useState, createContext, useEffect } from "react";
import { decodeToken, isExpired } from "react-jwt";
import { useNavigate } from "react-router-dom";
import { API_AUTH } from "../API";
import { TAAG_AGENCY_TOKEN } from "../contants";

export const AuthContext = createContext({});

const AuthContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetails, setUserDetails] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem(TAAG_AGENCY_TOKEN);
    const isTokenExpired = isExpired(user);
    if (user && isTokenExpired) {
      localStorage.removeItem(TAAG_AGENCY_TOKEN);
      navigate("/login");
    }
    if (user && !isTokenExpired) {
      setCurrentUser(decodeToken(user));
    }
    console.log({ user });
  }, []);

  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const res = await API_AUTH().get(`/user-details`, {
          params: {
            id: currentUser?.id,
            userType: currentUser?.userType,
          },
        });
        setUserDetails(res.data.data);
      } catch (error) {
        console.log({ error });
      }
    }
    if (currentUser) {
      fetchUserDetails();
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, userDetails }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
